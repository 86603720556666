.Navbar-container {

}

.Navbar-mobile-top-menu {
  display: none;
}

.Navbar-close {
  display: none;
}

.Navbar-nav-link {
  font-size: 16px;
  font-family: 'IBM Plex Mono';
}

@media(max-width: 800px) {
  .Navbar-container {
    position: fixed;
    top: 0;
    z-index: 2;
    background: #fafafc;
    bottom: 0;
    left: -200px;
    transition: left 0.5s;
  }

  .Navbar-container-open {
    left: 0;
  }

  .Navbar-close {
    right: 10px;
    top: 10px;
    position: absolute;
    display: block;
  }

  .Navbar-mobile-top-menu {
    display: block;
    position: fixed;
    width: 100%;
    padding: 8px 36px;
    padding-left: 10px;
    border-bottom: 1px solid #ccc;
    background: #fff;
    z-index: 1;
  }
}
