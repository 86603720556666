.CreateRound-container {
  width: 100%;
  margin: 0 auto;
  max-width: 500px;
}

.CreateRound-header {
  font-family: "IBM Plex Mono"
}

.CreateRound-entry-header {
  font-weight: 500;
  font-size: 16px;
}

.CreateRound-entry-description {
  font-size: 14px;
  margin-bottom: 8px;
  color: #88888A;
}

@media (max-width: 600px) {
  .CreateRound-container {
    flex-direction: column;
  }
}

.CreateRound-entry-container {
  margin-bottom: 28px;
}

.CreateRound-checklist-container {
  overflow: auto;
  background-color: #fff;
  border: 1px solid #D3D3D3;
  border-radius: 4px;
  max-height: 166px;
}

.ant-checkbox-group-item {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}
