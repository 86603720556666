.InviteBanner-container {
  background: #04eaa6;
  width: 100%;
  padding: 16px;
}

.InviteBanner-header {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 3px;
  font-family: 'IBM Plex Mono';
}

.InviteBanner-text-container {
  display: flex;
}

.InviteBanner-button-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.InviteBanner-card {
  width: 100%;
  font-weight: 600;
  margin-top: 28px;
  text-align: center;
  margin-bottom: 12px;
}

.InviteBanner-copied {
  color: #000;
  font-size: 14px;
  font-family: 'IBM Plex Mono';
  font-weight: 600;
  margin-top: 10px;
}
