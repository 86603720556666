.NewRoundBanner-container {
  background: #fafafc;
  width: 100%;
  padding: 16px;
  border: 1px solid #eaeaec;
  display: flex;
  align-items: center;
}

.NewRoundBanner-header {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 3px;
  font-family: 'IBM Plex Mono';
}

.NewRoundBanner-img {
  width: 100%;
  max-width: 300px;
  margin-right: 60px;
  opacity: 0.7;
}

.NewRoundBanner-text-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.NewRoundBanner-button-container {
  margin-top: 10px;
}

.NewRoundBanner-card {
  width: 100%;
  font-weight: 600;
  margin-top: 28px;
  text-align: center;
  margin-bottom: 12px;
}

.NewRoundBanner-copied {
  color: #000;
  font-size: 14px;
  font-family: 'IBM Plex Mono';
  font-weight: 600;
  margin-top: 10px;
}

@media(max-width: 600px) {
  .NewRoundBanner-container {
    flex-direction: column;
  }

  .NewRoundBanner-img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
    margin-right: 0;
    opacity: 0.7;
  }
}
