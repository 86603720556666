.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #ff0080; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
