.JoinCompany-intro-container {
  padding: 20px;
}

.JoinCompany-intro-section {
  margin-bottom: 20px;
  margin-top: 16px;
}

.JoinCompany-sub-intro-section {
  margin-bottom: 0px;
}

.JoinCompany-steps-footer-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.JoinCompany-steps-name-form-container {
  justify-content: center;
  margin-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
}

.JoinCompany-steps-business-container {
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 60px;
}

.JoinCompany-check-container .ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}

.JoinCompany-steps-joinurl-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding-left: 60px;
  padding-right: 60px;
}

.JoinCompany-steps-field-header {
  font-size: 16px;
  color: #000;
  margin-bottom: 6px;
  font-weight: 500;
}

.JoinCompany-card-modal .ant-card-body {
  height: 100%;
}

.JoinCompany-card-modal .ant-list-header {
  border-bottom: none;
}

.JoinCompany-steps-field {
  font-size: 14px;
}

@media (max-width: 500px) {
  .JoinCompany-steps-business-container {
    padding: 0;
  }
}
