.Router-desktop-padder {
  flex-grow: 1
}

.Router-main-area {
  width: 900px;
  background: #fff;
  overflow: auto;
  padding: 40px;
  padding-top: 60px;
}

.Router-wrapper {
  background: #fafafc;
  display: flex;
  height: 100vh;
  position: relative;
}

.Router-nav-link {
  margin-bottom: 10px;
  padding: 10px 20px;
  font-family: 'IBM Plex Mono'
}

.Router-active-link {
  font-weight: 700;
  background: #fff;
}

@media(max-width: 800px) {
  .Router-main-area {
    padding: 20px;
    padding-top: 70px;
  }
}
