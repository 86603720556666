.ViewRound-container {
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
}

.ViewRound-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.ViewRound-table-container {
  margin-top: 30px;
  width: 100%;
}
