.NewMemberBanner-container {
  background: #fafafc;
  width: 100%;
  padding: 16px;
  border: 1px solid #eaeaec;
}

.NewMemberBanner-header {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 3px;
  font-family: 'IBM Plex Mono';
}

.NewMemberBanner-text-container {
  display: flex;
}

.NewMemberBanner-button-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.NewMemberBanner-card {
  width: 100%;
  font-weight: 600;
  margin-top: 28px;
  text-align: center;
  margin-bottom: 12px;
}

.NewMemberBanner-copied {
  color: #000;
  font-size: 14px;
  font-family: 'IBM Plex Mono';
  font-weight: 600;
  margin-top: 10px;
}
