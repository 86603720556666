#stripe label {
  color: #2d2d2d;
  font-weight: 300;
  letter-spacing: 0.025em;
}

#stripe button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

#stripe button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

#stripe .split-form {
  display: flex;
  justify-content: space-between;
}

#stripe input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  min-width: 200px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

#stripe input::placeholder {
  color: #aab7c4;
}

#stripe input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

#stripe button {
  display: block;
  width: 100%;
  height: 37px;
  background-color: #6772e5;
  border-radius: 2px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

#stripe button:active {
  background-color: #6772e5;
}

#stripe .error {
  color: #c23d4b;
  margin: 5px 0;
}
