.Login-desktop-img {
  display: block;
  max-width: 300px;
  padding-left: 20px;
  margin: 0 auto;
}

.Login-mobile-img {
  display: none;
}

.Login-card {
  width: 100%;
  max-width: 700px;
}

.Login-card .ant-card-body {
  width: 100%;
  display: flex;
  align-items: center;
}

.Login-terms-container {
  margin-top: 5px;
  display: flex;
  font-size: 11px;
  padding-bottom: 40px;
}

.Login-button-fonts {
  font-size: 11px;
  color: #A0A0A0;
}

.Login-ms:hover {
  filter: brightness(120%);
}

.Login-ms {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px
}

.Login-footer {
  position:fixed;
  width:100%;
  text-align: center;
  bottom: 16px;
  font-size: 12px;
  font-family: "IBM Plex Mono"
}

@media (max-width: 600px) {
  .Login-mobile-img {
    display: block;
    width: 100%;
    max-width: 350px;
    height: auto;
    max-height: 350px;
    margin: 0 auto;
  }

  .Login-card .ant-card-body {
    flex-direction: column-reverse;
  }

  .Login-desktop-img {
    display: none;
  }
}
