:root {
  --border-color: #EAE9E7;
  --bg-color: #ffffff;
  --accent-color: #3086ff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.App-margin-bottom-10 {
  margin-bottom: 10px;
}

.App-margin-bottom-20 {
  margin-bottom: 20px;
}

.App-hidden {
  display: none !important;
}

.App-mini-button {
  font-size: 12px;
  padding: 10px;
}

.App-page-modal {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.App-page-modal-outer-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.App-page-modal-inner-container {
  background: #f5f5f7;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.App-page-modal-header {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
}

.App-page-modal-header-text {
  flex: 1;
  font-size: 22px;
  font-weight: 700;
}

.App-page-modal-header-close {
  font-size: 22px;
  color: #99999b;
  cursor: pointer;
}

.App-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 5px 0;
  background: #fff;
  margin-top: 10px;
  min-width: 150px;
  cursor: pointer;
  z-index: 9;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.App-dropdown div {
  white-space: nowrap;
  padding: 10px;
  cursor: pointer;
  color: #222;
}

.App-dropdown div:hover {
  background: #eee;
}

.App-input-title {
  font-size: 14px;
  padding: 4px;
  padding-bottom: 12px;
  font-weight: bold;
  flex: 1;
}

.App-input-field {
  font-size: 16px;
  padding: 10px;
  border: 1px solid transparent;
  outline: none;
  flex: 1;
  box-sizing: border-box;
  background: #fff;
  border-radius: 1px;
  transition: all 0.2s ease-in-out;
  width: 100%;
  resize: vertical;
  border: 1px solid #EAE9E7;
}

.App-textarea {
  font-size: 14px;
  padding: 14px 10px;
  background: #fff;
  border-radius: 1px;
  outline: none;
  flex: 1;
  box-sizing: border-box;
  resize: vertical;
  overflow: visible;
  transition: all 0.2s ease-in-out;
  width: 100%;
  border: 1px solid transparent;
}

.App-textarea:focus, .App-input-field:focus {
  border: 1px solid rgba(81, 203, 238, 1);
  background: #fff;
}

.App-divider {
  height: 1px;
  width: 100%;
  background: #EAE9E7;
}

.App-tiny-header {
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
  padding-left: 10px;
}

.App-select {
  width: 100%;
  font-size: 14px;
  height: 40px;
  background: #fff;
  border-color: transparent;
  border-radius: 1px;
  outline: none;
}

.App-common-subsection {
  margin-bottom: 15px;
}

.App-checkbox {
  background: #fff;
}

.App-nav-items div {
  display: inline-block;
  margin-right: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  font-size: 18px;
  color: #999998;
  cursor: pointer;
}

.App-nav-items div.active {
  color: #222;
  border-color: #222;
}

.App-nav-items div:hover {
  color: #222;
  border-color: #222;
}

.react-datepicker__input-container {
    display: block;
}

.react-datepicker-wrapper {
    width: 100%;
}

.card-info-container {
  background-color: #fff;
  border-radius: 4px;
  padding: 14px 10px 14px 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 660px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 30px;
  border: 1px solid #C8C8C8;
}

.brand-and-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.visa-logo {
  display: block;
  width: 100%;
  height: auto;
}

.ant-btn {
  font-family: 'IBM Plex Mono';
}

.header_style {
  font-size: 24px;
  font-weight: 500;
  color: #000;
  margin-bottom: 16px;
}

.sub_header_style {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 8px;
}

.mono-font {
  font-family: 'IBM Plex Mono'
}

.ant-select-dropdown {
  font-family: 'IBM Plex Mono'
}

.ant-modal-title {
  font-family: 'IBM Plex Mono';
  font-weight: 600 !important;
}

.ant-table-column-title {
  font-family: 'IBM Plex Mono';
  font-weight: 600 !important;
}

.ant-table-thead {
  background: #fafafc !important;
}


.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -36px;
}
.page-wrap:after {
  content: "";
  display: block;
}

.logged-in-page.page-wrap {
  max-height: 100vh;
  margin-bottom: 0;
}
.logged-in-page.page-wrap:after {
  content: "";
  display: none;
}

.site-footer, .page-wrap:after {
  height: 36px;
}
.site-footer {
  height: 36px;
  font-size: 12px;
  font-family: "IBM Plex Mono";
  text-align: center;
}
