.BusinessSettings-main-header {
  font-size: 22px;
  font-weight: 700;
  font-family: "IBM Plex Mono"
}

.BusinessSettings-sub-header {
  font-size: 14px;
  margin-bottom: 8px;
  color: #88888A;
  max-Width: 300px;
  width: 100%;
}

.BusinessSettings-content-header {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.BusinessSettings-content-description {
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 12px;
  font-family: 'IBM Plex Mono'
}

.BusinessSettings-info-container {
  margin-top: 8px;
  margin-bottom: 20px;
}

.BusinessSettings-content-container {
  margin-top: 8px;
  margin-bottom: 8px;
}
